import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import account_icon from '../assets/svg/user-icon.svg'
import list_icon from '../assets/svg/list-icon.svg'
import location_icon from '../assets/svg/location-icon.svg'
import heart_icon from '../assets/svg/heart.svg'
import key_icon from '../assets/svg/key-icon.svg'
import mail_icon from '../assets/svg/mail-icon.svg'
import logout_icon from '../assets/svg/logout-icon.svg'

import cart_icon from '../assets/svg/cart-icon.svg';
import map_icon from '../assets/svg/map-icon.svg';
import map_icon_active from '../assets/svg/map-icon-active.svg';
import cart_done_icon_active from '../assets/svg/cart-done-icon-active.svg';

import cart_done_icon from '../assets/svg/cart-done-icon.svg';
import cc_icon from '../assets/svg/credit-card-icon.svg';
import delete_icon from '../assets/svg/delete-icon.svg';
import paypal_icon from '../assets/images/paypal.png';

import image from '../assets/images/no-image.jpg';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import accepted from '../assets/svg/accepted.png';
import creditc from '../assets/svg/creditc.svg';
import mark1 from '../assets/svg/mark1.svg';
import payPalIcon from '../assets/svg/paypal.svg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal, ModalHeader, ModalBody, ModalFooter

} from 'reactstrap';

import OrderForm from '../components/forms/orderForm';
import CheckBox from '../components/forms/fields/check';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import moment from 'moment';
//sb-my22i20784971@personal.example.com
//?N5cO$/5

const countryMap = {
    'Sverige': 'se',
    'Danmark': 'dk',
    'Finland': 'fi',
    'Norge': 'no',
    'Litauen': 'lt',
    'Poland': 'pl',
    'Estland': 'ee',

}

const formatPayPalPrice = function (price, sep = 2) {
    let dec_point = '.';
    let thousands_sep = ',';

    var parts = parseFloat(price).toFixed(sep).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point);
}


class CheckoutForm extends React.Component {
    handleSubmit = async (event) => {
        event.preventDefault();
        const { stripe, elements } = this.props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (!error) {
            this.props.handleCheckout(paymentMethod.id);
        } else {
            console.log(error);
        }
    };


    async componentDidUpdate(prevProps) {
        if (!prevProps.verify3dSecure && this.props.verify3dSecure) {
            const { stripe, elements } = this.props;
            const { paymentIntent, error } = await stripe.confirmCardPayment(
                this.props.verify3dSecure.clientSecret
            );
            this.props.verify3dSecure.callback(paymentIntent.status, error, this.props.verify3dSecure.orderId);
        }
    }



    render() {
        const { stripe } = this.props;
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="card-number-wrap">
                    <CardElement options={{
                        iconStyle: "solid",
                        style: {
                            base: {
                                iconColor: "#333333",
                                color: "#333333",
                                fontWeight: 500,
                                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                                fontSize: "16px",
                                fontSmoothing: "antialiased",
                                ":-webkit-autofill": {
                                    color: "#333"
                                },
                                "::placeholder": {
                                    color: "#333"
                                }
                            },
                            invalid: {
                                iconColor: "#ff0000",
                                color: "#ff0000"
                            }
                        }
                    }} />
                </div>
                <button className="pay-button" type="submit" disabled={!stripe}>
                    {'BETALA'}
                </button>
            </form>
        );
    }
}

const InjectedCheckoutForm = ({ handleCheckout, verify3dSecure }) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} verify3dSecure={verify3dSecure} handleCheckout={handleCheckout} />
        )}
    </ElementsConsumer>
);

const stripePromise = loadStripe('pk_live_51NbJU8JNUOMckpWT7vtGC3b7e1CmBmYGWfPsF2XslX4ISH0JCHJbDrWo9PfN05fl6wcVOUDxsOj6UfVAsaMom1RH00na3pVVy8');
//const stripePromise = loadStripe('pk_live_51Lxo2kKqdc9ppXgCt3G1NdO7VGrdcPQ8txsibyiEyLpe1BpoCHIeYJz7cndZBLnVGktxCycmu09wiyal5xlsqdZc00ptlfEsF6');

//const stripePromise = loadStripe('pk_live_51IyNXZJU9CrRbC9ZcIDxjisFATs6uXeLlfdHuJcGFNDhYL2vn6sUswXCAE6EqWDIYhSozocrBS19kdyKYDgo04U5006vHsw502');
//const stripePromise = loadStripe('pk_test_51IyNXZJU9CrRbC9ZUEBsm4YCiMb5jLRyoJwNT6mN0jkgmbLS5d9he6w5zGeycreFxlSrBuprVwhIumUyFX7tasYr007VbrcNYe');
class CartPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.removeCartItem = this.removeCartItem.bind(this);
        this.updateQuantity = this.updateQuantity.bind(this);
        this.updateDeliveryAddress = this.updateDeliveryAddress.bind(this);
        this.updateDeliverTo = this.updateDeliverTo.bind(this);
        this.updateCurrentOrder = this.updateCurrentOrder.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            step: 0,
            deliveryMethod: 1,
            note: '',
            deliverTo: 'BillingAddress',
            paymentMethod: 'credit-card',
            _loading: true,
            imageErrors: {},
            giftBag: false,
            giftBagPrice: 15,
            deliveryMethods:
                [

                ]

        };

    }


    loadScript = () => {
        return new Promise((resolve, reject) => {
            var script = document.createElement('script');
            script.src = 'https://www.paypal.com/sdk/js?client-id=AaAuZXGtYsxjj98lxukRoc-UJ14ZuqH-QXyTS624wqY9LXH5MyRPoQRkDo4bVGoevbryH1_GsCtxrPTO&currency=' + (this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code);
            script.addEventListener('load', function () {
                resolve();
            });
            script.addEventListener('error', function (e) {
                reject(e);
            });
            document.body.appendChild(script);
        })
    };



    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (this.props.uData && this.props.uData.deliverTo && !this.state.deliverTo) {
            this.setState({
                deliverTo: this.props.uData.deliverTo
            })
        }

        if (prevState.step != this.state.step) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }

        if ((this.state.step != prevState.step && this.state.step == 2 && this.state.paymentMethod == 'PayPal') || (this.state.step == 2 && this.state.paymentMethod == 'PayPal' && prevState.paymentMethod != 'PayPal')) {
            console.log({
                intent: 'CAPTURE',
                application_context: {
                    brand_name: 'rossen.se',
                    //shipping_preference: 'NO_SHIPPING'
                },
                purchase_units: [{
                    amount: {
                        currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                        value: formatPayPalPrice(this.state.order.total),
                        breakdown: {
                            shipping: {
                                currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                value: formatPayPalPrice(this.state.order.DeliveryMethod),
                            },
                            item_total: {
                                currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                value: formatPayPalPrice(this.state.order.subtotal),
                            }
                        }
                    },
                    items: this.state.order.products.map((item) => {
                        return {
                            name: Object.translate(item, 'package.name', this.props.lang).length > 124 ? Object.translate(item, 'package.name', this.props.lang).substring(0, 124) + '...' : Object.translate(item, 'package.name', this.props.lang),
                            unit_amount: {
                                currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                value: formatPayPalPrice(parseFloat(item.price))
                            },
                            quantity: item.cartQuantity,
                            sku: item.sku,
                            category: 'PHYSICAL_GOODS'
                        }
                    })
                }]
            })
            this.loadScript().then(() => {
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        // This function sets up the details of the transaction, including the amount and line item details.
                        return actions.order.create({
                            intent: 'CAPTURE',
                            application_context: {
                                brand_name: 'rossen.se',
                                //shipping_preference: 'NO_SHIPPING'
                            },
                            purchase_units: [{
                                shipping: {
                                    name: { full_name: this.state.order.ShippingAddress.Name },
                                    phone: this.state.order.ShippingAddress.Phone,
                                    address: {
                                        address_line_1: this.state.order.ShippingAddress.Street,
                                        address_line_2: '',
                                        admin_area_1: '',
                                        admin_area_2: this.state.order.ShippingAddress.City,
                                        postal_code: this.state.order.ShippingAddress.Zipcode,
                                        country_code: countryMap[this.state.order.ShippingAddress.Country].toUpperCase(),

                                    }
                                },

                                amount: {
                                    currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                    value: formatPayPalPrice(this.state.order.total + 3),
                                    breakdown: {
                                        shipping: {
                                            currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                            value: formatPayPalPrice(this.state.order.DeliveryMethod),
                                        },
                                        handling: {
                                            currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                            value: formatPayPalPrice(this.state.order.Fee),

                                        },
                                        item_total: {
                                            currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                            value: formatPayPalPrice(this.state.order.subtotal),
                                        }
                                    }
                                },
                                items: this.state.order.products.map((item) => {
                                    return {
                                        name: Object.translate(item, 'package.name', this.props.lang).length > 124 ? Object.translate(item, 'package.name', this.props.lang).substring(0, 124) + '...' : Object.translate(item, 'package.name', this.props.lang),
                                        unit_amount: {
                                            currency_code: this.props.currency.code == 'kn' ? 'HRK' : this.props.currency.code,
                                            value: formatPayPalPrice(parseFloat(item.price))
                                        },
                                        quantity: item.cartQuantity,
                                        sku: item.Alias + '|||' + item.package.name.si,
                                        category: 'PHYSICAL_GOODS'
                                    }
                                })
                            }]
                        });
                    },
                    onApprove: (data, actions) => {
                        // This function captures the funds from the transaction.
                        return actions.order.capture().then((details) => {

                            this.props.socketIOClient.emit('executeOrder', { paypalId: details.id });

                            // This function shows a transaction success message to your buyer.
                            console.log(details);
                            //alert('Transaction completed by ' + details.payer.name.given_name);
                        });
                    }

                }).render(this.paypalContainer);
            }
            )
        }

        if (prevState.paymentMethod != this.state.paymentMethod) {
            this.props.socketIOClient.emit('updateCurrentOrder', { Fee: this.state.paymentMethod == 'PayPal' ? 3 : 0 });
            this.setState({ _loading: true });
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });




        this.props.socketIOClient.on('updateCurrentOrder', (data) => {
            this.props.socketIOClient.emit("fetchCurrentOrder", { lang: this.props.lang });
            this.setState({
                cartChanged: null,
                _loading: null,
                imageErrors: {}
            })


            if (this.state._finishOrder) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.props.socketIOClient.emit('executeOrder', {});
                this.setState({
                    _finishOrder: null,
                })
            }

            if (data && data.executeOrderOnResponse) {
                this.executeOrder(data.executeOrderOnResponse)
            }

        })
        this.props.socketIOClient.on('updateUserData', (data) => {
            console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
                this.setState({
                    showForm: null,
                    initialValues: null,
                    _loading: null
                })

            }
        });



        this.props.socketIOClient.on('addToCart', (data) => {
            this.props.socketIOClient.emit("fetchCurrentOrder", {});

        })

        this.props.socketIOClient.on('fetchAllProducts', (data) => {
            this.setState({
                freeProducts: data
            })
        })

        this.props.socketIOClient.on('fetchCurrentOrder', (data) => {

            if (data && data.addToCartEmitObj && data.addToCartEmitObj._id) {
                if (data.addToCartEmitObj.quantity) {
                    this.props.socketIOClient.emit('addToCart', data.addToCartEmitObj);
                } else {
                    this.removeCartItem(data.addToCartEmitObj)

                }
            }
            if (data && data.addToCartEmitObj2 && data.addToCartEmitObj2._id) {
                if (data.addToCartEmitObj2.quantity) {
                    this.props.socketIOClient.emit('addToCart', data.addToCartEmitObj2);
                } else {
                    this.removeCartItem(data.addToCartEmitObj2)

                }
            }

            if (data && data.removeGratisProducts) {
                this.props.socketIOClient.emit("removeCartItem", { deleteGratisProducts: true });
            }
            if (data.order && ((data.order.subtotal >= 30 && data.order.currency.code == 'EUR') || (data.order.subtotal >= 240 && data.order.currency.code == 'kn')) && new Date().getTime() < new Date(2021, 10, 27, 0, 0, 0).getTime()) {
                this.props.socketIOClient.emit('fetchAllProducts', {});

                let check = -1;

                for (let i = 0; i < data.order.products.length; i++) {
                    if (data.order.products[i].package && data.order.products[i].package.freeProduct) {
                        check = i;
                        break;
                    }
                }



                this.setState({
                    freeProduct: check == -1
                })
            } else {

                let check = -1;

                for (let i = 0; i < data.order.products.length; i++) {
                    if (data.order.products[i].package && data.order.products[i].package.freeProduct) {
                        check = i;
                        break;
                    }
                }

                if (check !== -1) {
                    this.props.socketIOClient.emit("removeCartItem", { productId: data.order.products[check]._id });

                }


                this.setState({
                    freeProduct: false
                })

            }
            console.log(data);
            this.setState({
                order: data.order,
                deliveryMethods: data.deliveryMethods,
                giftBag: data.order && data.order.giftBag ? true : false,
                gratisProducts: data.gratisProducts
            });

            if (data.order.BillingMethod === 'Per-Nachname' || data.order.BillingMethod === 'Bargeld') {
                this.setState({
                    _loading: null
                })
            }
            /*this.setState({
                cartItems: data
            })*/
        });

        this.props.socketIOClient.on('3dsecure', async (data) => {


            this.setState({
                verify3dSecure: {
                    clientSecret: data.clientSecret,
                    orderId: data.orderId,
                    callback: (status, error, orderId) => {

                        if (error) return alert("Error in payment, please try again later");
                        if (status === "succeeded") {

                            this.props.socketIOClient.emit('cartInfo', {});
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                            this.props.showInfoMessage(this.props.translate('Narudžba je primljena.'));
                            this.setState({
                                step: 4
                            })

                            this.props.socketIOClient.emit('complete3dSecure', { orderId: orderId });

                        }

                    }
                }
            })

        });

        this.props.socketIOClient.on('executeOrder', (data) => {
            if (data.requiresAction) {
                return;
            }

            this.props.socketIOClient.emit('cartInfo', {});
            window.scrollTo({ top: 0, behavior: 'smooth' })
            if (data.successful) {
                this.props.showInfoMessage(this.props.translate('Narudžba je primljena.'));
                this.setState({
                    step: 4
                })
            }
        })


        this.props.socketIOClient.on('removeCartItem', (data) => {
            this.props.socketIOClient.emit("cartInfo", {});
            this.props.socketIOClient.emit("fetchCurrentOrder", { lang: this.props.lang });

        });


        this.props.socketIOClient.emit("fetchCurrentOrder", { lang: this.props.lang });


        try {
            let search = this.props[0].location.search;
            search = search.replace('?', '')
            let splittedSearch = search.split('&');
            for (let i = 0; i < splittedSearch.length; i++) {
                if (splittedSearch[i].split('=')[0] == 'products' && splittedSearch[i].split('=').length >= 2) {

                    let products = splittedSearch[i].split('=')[1].split(',');

                    for (let i = 0; i < products.length; i++) {
                        this.props.addToCart({ _id: products[i], package: [null] })
                    }

                }
            }

        } catch (e) {
        }

        //this.props.socketIOClient.emit("fetchDeliveryMethods", {});



    }

    updateDeliveryAddress(data) {
        this.setState({
            _loading: true
        })
        console.log(data);
        let obj = {
            'ShippingAddress.Name': data.Name,
            'ShippingAddress.Street': data.Street,
            'ShippingAddress.Zipcode': data.Zipcode,
            'ShippingAddress.City': data.City,
            'ShippingAddress.Country': data.Country,
            'ShippingAddress.EMail': data.EMail,
            'ShippingAddress.Company': data.Company,
            'ShippingAddress.Phone': data.Phone,
            'ShippingAddress.Country': data.Country,
            'ShippingAddress.Region': data.Region,


        }

        this.props.socketIOClient.emit("updateUserData", obj);
    }

    updateDeliverTo() {
        let obj = {
            'deliverTo': this.state.deliverTo
        };

        this.setState({
            _loading: true
        })

        this.props.socketIOClient.emit("updateUserData", obj);

    }

    updateCurrentOrder() {
        this.setState({
            _loading: true
        });

        let obj = {
            products: this.state.order.products
        }

        if (this.state.Coupon) {
            obj.Coupon = this.state.Coupon;
        }

        this.props.socketIOClient.emit("updateCurrentOrder", obj);

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;

        this.props.socketIOClient.removeAllListeners("updateCurrentOrder");
        this.props.socketIOClient.removeAllListeners("updateUserData");
        this.props.socketIOClient.removeAllListeners("fetchCurrentOrder");
        this.props.socketIOClient.removeAllListeners("executeOrder");
        this.props.socketIOClient.removeAllListeners("removeCartItem");
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.contains && event && event.target && !this.wrapperRef.contains(event.target)) {
            this.setState({ gratisProductsDropDown: null })
        }
    }


    login(data) {
        console.log(data);
        this.props.socketIOClient.emit("userLogin", data);
    }


    removeCartItem(item) {
        console.log(true);
        this.props.socketIOClient.emit("removeCartItem", { productId: item._id });
    }

    updateQuantity(idx, val) {
        let order = this.state.order;

        order.products[idx].cartQuantity = val;

        this.setState({
            order: order,

        }, this.updateCurrentOrder);

    }
    updateOrderGiftBag = () => {
        this.props.socketIOClient.emit("updateCurrentOrder", { giftBag: this.state.giftBag, giftBagPrice: this.state.giftBag ? this.state.giftBagPrice : 0 });

    }

    executeOrder = (executeOrderOnResponse) => {
        if (executeOrderOnResponse) {
            this.setState({
                step: 3
            }, () => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                if (executeOrderOnResponse === 'on-site') {
                    this.props.socketIOClient.emit('executeOrder', { paymentOnSite: true });
                } else if (executeOrderOnResponse === 'payment-on-account') {
                    this.props.socketIOClient.emit('executeOrder', { paymentOnAccount: true });
                }


            })
        }

    }
    formatMessage = (number) => {
        let message;
        if (number > 0) {
            if (number === 1) message = <>{this.props.translate("Ostvarili ste pravo na")} <span>{this.props.translate("1 POKLON PROIZVOD")}</span>, {this.props.translate("molimo izaberite gratis proizvod")}.</>;
            else if (number > 1) message = <>{this.props.translate("Ostvarili ste pravo na")} <span>{number} {this.props.translate("POKLON PROIZVODA")}</span>, {this.props.translate("molimo izaberite gratis proizvode")}.</>;
        }

        return message;
    }

    render() {
        let cartItems = this.state.order ? this.state.order.products : [];
        console.log('order', this.state.order);

        let canHaveGratisProducts = this.state.order && this.state.order.canHaveGratisProducts ? this.state.order.canHaveGratisProducts : 0;
        let numberOfGratisProducts = 0;
        let gratisProductsInCart = []
        for (let i = 0; i < cartItems.length; i++) {
            if (cartItems[i] && cartItems[i].package && cartItems[i].package.gratisProduct) {
                numberOfGratisProducts += cartItems[i].cartQuantity;
                gratisProductsInCart.push(`${cartItems[i]._id}${cartItems[i].package.name && cartItems[i].package.name.si}`)
            }
        }
        let gratisProductModal = canHaveGratisProducts > numberOfGratisProducts ? true : false
        let priceTillGratis = this.state.order && this.state.order.priceTillGratis ? this.state.order.priceTillGratis : null;
        let numOfProducts = priceTillGratis && priceTillGratis.numOfProducts ? Number(priceTillGratis.numOfProducts) : null;
        let numOfProductsString = null;
        if (numOfProducts > 1) {
            numOfProductsString = numOfProducts;

        }
        let messageCanHaveGratisProducts = this.formatMessage(canHaveGratisProducts)
        return (
            <div className="account-wrap cart-wrap">

                {
                    /* !this.props.uData ? <Redirect to='/login' ></Redirect> : null}*/
                }

                <Container>
                    <Row>
                        <Col lg="12" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <div className="arrow-steps">
                                            <button onClick={() => this.setState({ step: 0 })} className={this.state.step >= 0 ? 'active' : ''}><span>{this.props.translate('1. Korpa')}</span></button>
                                            <button onClick={() => { if (cartItems.length) this.setState({ step: 1 }) }} className={this.state.step >= 1 ? 'active' : ''}><span>{this.props.translate('2. Dostava')}</span></button>
                                            <button onClick={() => { if (this.state.order && this.state.order.ShippingAddress && this.state.order.ShippingAddress.Name) this.setState({ step: 2 }) }} className={this.state.step >= 2 ? 'active' : ''}><span>{this.props.translate('3. Plaćanje')}</span></button>

                                        </div>
                                    </Col>
                                    {/*this.state.step !== 2 ?
                                        <Col lg="12">
                                            <ul className="cart-progress">

                                                <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                    <div className="icon">
                                                        <Isvg src={cart_icon} />
                                                    </div>
                                                    <p>{this.props.translate('1. Korpa')}</p>
                                                    <p>{this.props.translate('Količina, dostava i plaćanje')}</p>

                                                </li>



                                                <li className={this.state.step === 1 ? 'active' : this.state.step == 2 ? 'done' : ''}>
                                                    <div className="icon">
                                                        <Isvg src={this.state.step == 1 ? cart_done_icon_active : cart_done_icon} />
                                                    </div>
                                                    <p>{this.props.translate('2. Potvrda')}</p>
                                                    <p>{this.props.translate('Potvrda o prijemu narudžbe')}</p>


                                                </li>

                                            </ul>
                                        </Col>
                                        :
                                        null*/
                                    }


                                    <Col lg="12">
                                        {this.state.step === 0 || this.state.step === 1 || this.state.step === 2 ?
                                            <Container className="box-container">

                                                <Row>

                                                    {this.state.step != 2 ?
                                                        <Col lg="12">

                                                            <p>
                                                                {this.props.translate('Proverite svoju narudžbu. Artikle možete obrisati ili promjeniti količinu.')}
                                                            </p>
                                                        </Col>
                                                        :
                                                        null}


                                                    <Col lg="12">
                                                        {this.state.order && this.state.order.remainingToFreeShiping ? <div className='free-shipping-calc'><Isvg src={mark1} /> {this.props.translate('Nedostaje vam')} <span className='price'>{this.state.order.remainingToFreeShiping.toFixed(2).replace('.', ',')} {this.props.currency.code}</span> {this.props.translate('do besplatne dostave!')}  </div> : null}

                                                        {this.state.step != 2 ?
                                                            <Link to='/category' className='back'>{this.props.translate('Nastavite kupovati')}</Link> : null}
                                                    </Col>



                                                    <Col className={this.state.step !== 0 ? 'small-table' : ''} lg={{ size: this.state.step === 0 ? 12 : 6, order: this.state.step === 0 ? 0 : 1 }} xs={{ size: 12, order: 0 }}>
                                                        {
                                                            this.state.step === 2 && this.state.order && this.state.order.ShippingAddress ?
                                                                <div className="address-info">
                                                                    <h3>{this.props.translate('Adresa za dostavu')}</h3>

                                                                    <p>{this.state.order.ShippingAddress.Name}</p>
                                                                    <p>{this.state.order.ShippingAddress.Street}<br /> {this.state.order.ShippingAddress.City}, {this.state.order.ShippingAddress.PostalCode}</p>
                                                                    <p>{this.state.order.ShippingAddress.Country ? this.state.order.ShippingAddress.Country : ''} </p>
                                                                    <p>{this.state.order.ShippingAddress.Phone}</p>
                                                                    <p>{this.state.order.ShippingAddress.EMail}</p>

                                                                </div>
                                                                :
                                                                null
                                                        }


                                                        <Container className="cart-table">
                                                            <Row className="header">
                                                                <Col lg="2" xs="2">{this.props.translate('Količina')}</Col>
                                                                <Col lg="7" xs="7">{this.props.translate('Naziv artikla')}</Col>


                                                                <Col lg={this.state.step === 0 ? "2" : "3"} xs={this.state.step === 0 ? "2" : "3"} className="number">{this.props.translate('Ukupno')}</Col>
                                                                {this.state.step === 0 ? <Col lg="1" xs="1"></Col> : null}

                                                            </Row>

                                                            {
                                                                cartItems.map((item, idx) => {
                                                                    let gratisProduct = false;
                                                                    if (item.package && (item.package.gratisProduct || item.package._gratisProduct2)) {
                                                                        gratisProduct = true;
                                                                    }
                                                                    return (
                                                                        <Row className="article-info" key={idx}>
                                                                            {item.package && item.package.freeProduct ?
                                                                                <Col lg="2" xs="2">
                                                                                </Col>

                                                                                :
                                                                                <Col lg="2" xs="2">
                                                                                    {this.state.step === 0 ?
                                                                                        <><input type="text" value={item.cartQuantity} disabled={gratisProduct} onChange={(e) => {
                                                                                            if (!gratisProduct)
                                                                                                this.updateQuantity(idx, e.target.value)
                                                                                        }} /> <label>{this.props.translate('kom.')}</label></>
                                                                                        :
                                                                                        <span className="quantity">x{item.cartQuantity}</span>
                                                                                    }
                                                                                </Col>
                                                                            }
                                                                            <Col lg={"7"} xs={"7"} className="article-name">
                                                                                <img src={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : image} />
                                                                                <span>{Object.translate(item, 'package.name', this.props.lang)}
                                                                                    <br />
                                                                                    <span className='cart-ts'>{this.props.translate('Rezervisano do')} <span>{moment.unix(item.ts + 3 * 60 * 60).format('HH:mm')}</span></span>
                                                                                </span>
                                                                            </Col>
                                                                            <Col lg={this.state.step === 0 ? "2" : "3"} xs={this.state.step === 0 ? "2" : "3"} className="number">
                                                                                {item.price ? (parseFloat(item.price) * item.cartQuantity).formatPrice(this.state.order ? this.state.order.currency : this.props.currency) : null}
                                                                            </Col>
                                                                            {this.state.step === 0 ?
                                                                                <Col lg="1" xs="1" className="delete" onClick={() => {
                                                                                    if (item.package && !item.package._gratisProduct && !item.package._gratisProduct2)
                                                                                        this.removeCartItem(item)
                                                                                }}>
                                                                                    <Isvg src={delete_icon} />
                                                                                </Col>
                                                                                :
                                                                                null
                                                                            }
                                                                        </Row>
                                                                    )
                                                                })

                                                            }
                                                            {/* Poklon kesa */}
                                                            <Row className="article-info" key={'gift-bag'}>
                                                                <Col lg="5" xs="5">
                                                                    <CheckBox
                                                                        value={this.state.giftBag}
                                                                        onChange={(value) => this.setState({ giftBag: value }, () => this.updateOrderGiftBag())}
                                                                        label={this.props.translate('Poklon kesa') + ' 15 kr'}
                                                                        style={{ marginBottom: 0 }}
                                                                    />

                                                                </Col>
                                                                <Col lg="4" xs="4" className="article-name">

                                                                </Col>
                                                                <Col lg="2" xs="2" className="number">
                                                                    {this.state.giftBag ? this.state.giftBagPrice.formatPrice(this.props.currency) : null}
                                                                </Col>
                                                                <Col lg="1" xs="1" className="delete">
                                                                </Col>

                                                            </Row>



                                                            {this.state.order ?
                                                                this.state.step === 0 && this.state.order.DeliveryMethod != 0 && ((this.state.order.country == 'Sverige' && !this.state.order.ShippingAddress) || (this.state.order.ShippingAddress && this.state.order.ShippingAddress.Country == 'Sverige')) ?
                                                                    <Row className="article-info">
                                                                        <Col lg="5" xs="3">
                                                                            {this.props.translate('Cijena dostave')}
                                                                        </Col>
                                                                        <Col lg="4" xs="6">


                                                                        </Col>
                                                                        <Col lg="2" xs="2" className="number">
                                                                            {parseFloat(this.state.order ? this.state.order.DeliveryMethod : 0).formatPrice(this.state.order ? this.state.order.currency : this.props.currency)}
                                                                        </Col>
                                                                    </Row>



                                                                    :


                                                                    <Row className="article-info">
                                                                        <Col lg="5" xs="6">
                                                                            {this.props.translate('Cijena dostave')}
                                                                        </Col>
                                                                        <Col lg="6" xs="6" className="number">
                                                                            {parseFloat(this.state.order ? this.state.order.DeliveryMethod : 0).formatPrice(this.state.order ? this.state.order.currency : this.props.currency)}
                                                                        </Col>
                                                                    </Row>
                                                                :
                                                                null}
                                                            {this.state.order && this.state.order.Fee ?
                                                                <Row className="article-info">
                                                                    <Col lg="5" xs="6">
                                                                        {this.props.translate('Fee')}
                                                                    </Col>
                                                                    <Col lg="6" xs="6" className="number">
                                                                        {parseFloat(this.state.order ? this.state.order.Fee : 0).formatPrice(this.state.order ? this.state.order.currency : this.props.currency)}
                                                                    </Col>
                                                                </Row>

                                                                :
                                                                null}

                                                            <Row className="article-info">
                                                                <Col lg={"9"} xs={"9"} className="text-bold">
                                                                    <div>{this.props.translate('UKUPNO')}<br />
                                                                        <span className="vat">{this.props.translate('Uključujući ')} {this.state.order ? (this.state.order.total - ((this.state.order.total * 100) / (100 + this.state.order.vat))).formatPrice(this.state.order ? this.state.order.currency : this.props.currency) : 'NaN'} {this.props.translate('poreza')}</span>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={this.state.step === 0 ? "2" : "3"} xs={this.state.step === 0 ? "2" : "3"} className="number text-bold">
                                                                    {this.state.order && this.state.order.total.formatPrice(this.state.order ? this.state.order.currency : this.props.currency)}
                                                                </Col>
                                                                {this.state.step === 0 ?
                                                                    <Col lg="1" xs="1" className="delete">

                                                                    </Col>
                                                                    :
                                                                    null
                                                                }
                                                            </Row>
                                                            {
                                                                gratisProductModal || priceTillGratis ?
                                                                    <Row className='gratis-product-cart-wrap'>
                                                                        {
                                                                            priceTillGratis ?
                                                                                <Col lg="12">
                                                                                    <div style={{ marginBottom: 20 }}>
                                                                                        <div className='gratis-product-cart-message'>
                                                                                            <h3>{this.props.translate("Nedostaje vam")} {priceTillGratis.remaining.formatPrice(this.props.currency)} {this.props.translate("do")} <span style={{ color: '#F7617E' }}>{numOfProductsString ? `${numOfProductsString} ` : ''}{this.props.translate("POKLON PROIZVODA!")}</span></h3>
                                                                                            {
                                                                                                priceTillGratis.percent && priceTillGratis.sum && priceTillGratis.limit ?
                                                                                                    <div className='gratis-product-message-percent-wrap'>
                                                                                                        <div className='percent-line'><div className='percent-line-pink' style={{ width: `${priceTillGratis.percent}%` }}></div></div>
                                                                                                        <div className='gratis-product-message-percent-prices'>
                                                                                                            <h6>{priceTillGratis.sum.formatPrice(this.props.currency)}</h6>
                                                                                                            <h6>{priceTillGratis.limit.formatPrice(this.props.currency)}</h6>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                            <div className='buttons'>
                                                                                                <Link to='/category'>
                                                                                                    <button>{this.props.translate('Nastavi kupovati')}</button>
                                                                                                </Link>
                                                                                                {
                                                                                                    numOfProducts ?
                                                                                                        <p style={{ marginBottom: 0, color: '#F7617E' }}>* {this.props.translate('za')} {numOfProducts} {this.props.lang !== 'de' ? this.props.translate('poklon') : null} {numOfProducts > 1 ? this.props.translate('proizvoda') : this.props.translate('proizvod')}</p>
                                                                                                        :
                                                                                                        null
                                                                                                }


                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            gratisProductModal ?
                                                                                <>
                                                                                    <Col lg="12">
                                                                                        <div className='flex-center-mob-column'>
                                                                                            {
                                                                                                messageCanHaveGratisProducts ?
                                                                                                    <h6>{messageCanHaveGratisProducts}</h6> //  <h6>{this.props.translate('Ostvarili ste pravo na poklon proizvod! Izaberite gratis proizvod.')} <span>({numberOfGratisProducts}/{canHaveGratisProducts})</span></h6>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                this.state.gratisProducts && this.state.gratisProducts.length ?
                                                                                                    <div className='choose-gratis-wrap' ref={(node) => this.wrapperRef = node}>
                                                                                                        <button className="button choose-gratis-btn" onClick={() => this.setState({ gratisProductsDropDown: !this.state.gratisProductsDropDown })}>{this.props.translate('GRATIS PROIZVODI')}</button>
                                                                                                        {
                                                                                                            this.state.gratisProductsDropDown ?
                                                                                                                <div className='choose-gratis-items-wrap'>
                                                                                                                    {
                                                                                                                        this.state.gratisProducts.map((item, idx) => {
                                                                                                                            let disabled = numberOfGratisProducts >= canHaveGratisProducts ? true : false;
                                                                                                                            if (!disabled && item._id && item.package && item.package.name && gratisProductsInCart.indexOf(`${item._id}${item.package.name && item.package.name.si} - GRATIS`) !== -1) {
                                                                                                                                disabled = true;
                                                                                                                            }
                                                                                                                            return (
                                                                                                                                <div className="free-product">
                                                                                                                                    <img src={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null} />
                                                                                                                                    <h6>{Object.translate(item, 'Name', this.props.lang)}</h6>
                                                                                                                                    <button disabled={disabled} className={disabled ? 'button disabled-btn' : 'button'} onClick={() => {
                                                                                                                                        if (!disabled)
                                                                                                                                            // this.props.socketIOClient.emit('addToCart', { _id: item._id, quantity: 1, package: { ...item.package, name: item.package.name + ' - GRATIS', price: 0, gratisProduct: true } });
                                                                                                                                            this.props.socketIOClient.emit('addToCart', { _id: item._id, quantity: 1, package: { ...item.package, unitPrice: { 'si': 0, 'de': 0, 'at': 0, 'hr': 0 }, name: { ...item.package.name, si: item.package.name.si + ' - GRATIS', de: item.package.name.si + ' - GRATIS', en: item.package.name.si + ' - FREE' }, price: 0, gratisProduct: true } });



                                                                                                                                    }}>{this.props.translate('IZABERI')}</button>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>

                                                                                    </Col>

                                                                                </>
                                                                                :
                                                                                null
                                                                        }

                                                                    </Row>

                                                                    :
                                                                    null
                                                            }



                                                            {this.state.freeProduct ?
                                                                <Modal size="lg" isOpen={this.state.freeProduct} className="free-products-modal">
                                                                    <ModalHeader><span>{this.props.translate('Vaša narudžba prelazi 30EUR')}</span><br /> {this.props.translate('IZABERITE GRATIS PROIZVOD')}</ModalHeader>
                                                                    <ModalBody>
                                                                        <Row>
                                                                            {
                                                                                this.state.freeProducts && this.state.freeProducts.length ?
                                                                                    this.state.freeProducts.map((item, idx) => {
                                                                                        return (
                                                                                            <Col lg="4" xs="6">
                                                                                                <div className="free-product">
                                                                                                    <img src={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null} />
                                                                                                    <h6>{Object.translate(item, 'Name', this.props.lang)}</h6>
                                                                                                    <button className="button" onClick={() => {
                                                                                                        this.setState({ freeProduct: null })
                                                                                                        this.props.socketIOClient.emit('addToCart', { _id: item._id, quantity: 1, package: { ...item.package[0], unitPrice: { 'si': 0, 'de': 0, 'at': 0, 'hr': 0 }, name: { ...item.package[0].name, si: item.package[0].name.si + ' - GRATIS', de: item.package[0].name.si + ' - GRATIS', en: item.package[0].name.si + ' - FREE' }, price: 0, freeProduct: true } });
                                                                                                    }}>{this.props.translate('IZABERI')}</button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </Row>
                                                                    </ModalBody>
                                                                </Modal>
                                                                :
                                                                null
                                                            }

                                                        </Container>

                                                        {this.state.step === 0 && cartItems.length ?
                                                            <div className="cart-next-button-wrap">
                                                                <button onClick={() => this.setState({ step: 1 })} className="cart-next-button">{this.props.translate('Sledeći korak')}</button>
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                    </Col>



                                                    {
                                                        this.state.step == 1 ?
                                                            <Col lg={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }} className="form-container">
                                                                <h3>{this.props.translate('Izaberite nacin placanja')}</h3>

                                                                <div className="payment-options">
                                                                    <div className={this.state.paymentMethod == 'credit-card' ? 'active' : ''}>
                                                                        <button onClick={() => this.setState({ paymentMethod: 'credit-card' })} >{this.props.translate("Kreditna kartica")} <img src={accepted} /></button>
                                                                        <div className="credit-card">

                                                                            <Isvg src={creditc} />
                                                                            <p>{this.props.translate('Nakon dovršetka narudžbe, preusmjerit ćemo te na kreditnu karticu da završiš kupovinu.')}</p>

                                                                        </div>

                                                                    </div>
                                                                    {/* <div className={this.state.paymentMethod == 'PayPal' ? 'active' : ''}>
                                                                        <button onClick={() => this.setState({ paymentMethod: 'PayPal' })} >{this.props.translate("PayPal")} <img src={payPalIcon} /></button>
                                                                        <div className="credit-card">

                                                                            <Isvg src={payPalIcon} />
                                                                            <p>{this.props.translate('Nakon dovršetka narudžbe, preusmjerit ćemo te na PayPal da završiš kupovinu.')}</p>
                                                                            <p>{this.props.translate('Dodatek 3,00 EUR.')}</p>

                                                                        </div>

                                                                    </div> */}

                                                                    {/* {this.props.lang != 'de' ?
                                                                        <div className={this.state.paymentMethod == 'on-site' ? 'active' : ''}>

                                                                            <button onClick={() => this.setState({ paymentMethod: 'on-site' })} >{this.props.translate("Plaćanje pouzećem")}</button>
                                                                            <div className="payment-account">
                                                                                <h6>{this.props.translate('VAŽNA OBAVIJEST!')}</h6>
                                                                                <p>{this.props.translate('Plaćanje pouzećem (COD) moguće je samo za domaće kupce iz Hrvatske (HR). Ako ste odabrali plaćanje pouzećem a nalazite se u drugoj državi, nećemo moći ispuniti vašu narudžbu. Slobodno nam se obratite na info@rossen.com.hr i rado ćemo vam ponuditi druge mogućnosti plaćanja.')}</p>

                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        null}
                                                                    <div className={this.state.paymentMethod == 'payment-on-account' ? 'active' : ''}>
                                                                        <button onClick={() => this.setState({ paymentMethod: 'payment-on-account' })} >{this.props.translate("Uplata na racun")}</button>
                                                                    </div>
 */}
                                                                </div>


                                                                <h3>{this.props.translate('Adresa za dostavu')}</h3>


                                                                {this.props.uData ?
                                                                    <OrderForm lang={this.props.lang} initialValues={this.props.uData.deliverTo == 'BillingAddress' && this.props.uData.BillingAddress ? { ...this.props.uData.BillingAddress, Name: this.props.uData.BillingAddress.Name } : this.props.uData.ShippingAddress ? { ...this.props.uData.ShippingAddress, Name: this.props.uData.ShippingAddress.Name } : null} translate={this.props.translate} onSubmit={(data) => {
                                                                        if (data.Country != 'Sverige') {
                                                                            delete data.Region;
                                                                        }
                                                                        this.setState({ ShippingCountry: data.Country });

                                                                        if (this.props.uData) {
                                                                            this.updateDeliveryAddress(data);

                                                                            this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data, executeOrderOnResponse: this.state.paymentMethod == 'on-site' || this.state.paymentMethod == 'payment-on-account' ? this.state.paymentMethod : false });
                                                                            this.setState({ _loading: true });
                                                                        } else {
                                                                            this.setState({ _loading: true });
                                                                            this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data, executeOrderOnResponse: this.state.paymentMethod == 'on-site' || this.state.paymentMethod == 'payment-on-account' ? this.state.paymentMethod : false })
                                                                        }


                                                                        if (this.state.paymentMethod !== 'on-site' && this.state.paymentMethod !== 'payment-on-account') {
                                                                            this.setState({
                                                                                step: 2
                                                                            })
                                                                        }
                                                                    }}></OrderForm>

                                                                    :

                                                                    <OrderForm lang={this.props.lang} initialValues={this.state.order && this.state.order.ShippingAddress ? this.state.order.ShippingAddress : { Country: this.state.order ? this.state.order.country : 'Sverige' }} translate={this.props.translate} onSubmit={(data) => {
                                                                        if (data.Country != 'Sverige') {
                                                                            delete data.Region;
                                                                        }
                                                                        this.setState({ ShippingCountry: data.Country });

                                                                        if (this.props.uData) {
                                                                            this.updateDeliveryAddress(data);

                                                                            this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data, executeOrderOnResponse: this.state.paymentMethod == 'on-site' || this.state.paymentMethod == 'payment-on-account' ? this.state.paymentMethod : false });
                                                                            this.setState({ _loading: true });
                                                                        } else {
                                                                            this.setState({ _loading: true });
                                                                            this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data, executeOrderOnResponse: this.state.paymentMethod == 'on-site' || this.state.paymentMethod == 'payment-on-account' ? this.state.paymentMethod : false })
                                                                        }


                                                                        if (this.state.paymentMethod !== 'on-site' && this.state.paymentMethod !== 'payment-on-account') {
                                                                            this.setState({
                                                                                step: 2
                                                                            })
                                                                        }

                                                                    }}></OrderForm>
                                                                }





                                                            </Col>

                                                            :

                                                            null
                                                    }

                                                    {
                                                        this.state.step == 2 ?
                                                            <Col lg={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }} className="form-container">
                                                                <h3>{this.props.translate('Izaberite nacin placanja')}</h3>

                                                                <div className="payment-options">
                                                                    <div className={this.state.paymentMethod == 'credit-card' ? 'active' : ''}>
                                                                        <button onClick={() => this.setState({ paymentMethod: 'credit-card' })} >{this.props.translate("Kreditna kartica")} <img src={accepted} /></button>
                                                                        <div>
                                                                            <Elements stripe={stripePromise}>
                                                                                <InjectedCheckoutForm verify3dSecure={this.state.verify3dSecure} handleCheckout={(id) => {
                                                                                    this.setState({
                                                                                        stripeLoading: true
                                                                                    }, () => {

                                                                                        this.props.socketIOClient.emit('executeOrder', { id });

                                                                                    })

                                                                                }} />
                                                                            </Elements>

                                                                        </div>
                                                                        {this.state.stripeLoading ?
                                                                            <div className="stripe-loading">
                                                                                <div className="order-loader">
                                                                                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                                                </div>
                                                                                <h3 className="order-done-title">{this.props.translate('Obrada narudžbe je u toku.')}</h3>
                                                                                <h6 className="order-done-subtitle">{this.props.translate('Molimo pričekajte.')}</h6>

                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                    </div>
                                                                    {/* <div className={this.state.paymentMethod == 'PayPal' ? 'active' : ''}>
                                                                        <button onClick={() => this.setState({ paymentMethod: 'PayPal' })} >{this.props.translate("PayPal")} <img src={payPalIcon} /></button>
                                                                        <div ref={(node) => this.paypalContainer = node}>

                                                                        </div>
                                                                        {this.state.payPalLoading ?
                                                                            <div className="stripe-loading">
                                                                                <div className="order-loader">
                                                                                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                                                </div>
                                                                                <h3 className="order-done-title">{this.props.translate('Obrada narudžbe je u toku.')}</h3>
                                                                                <h6 className="order-done-subtitle">{this.props.translate('Molimo pričekajte.')}</h6>

                                                                            </div>
                                                                            :
                                                                            null
                                                                        }

                                                                    </div> */}

                                                                    {/* {this.props.lang != 'de' ?
                                                                        <div className={this.state.paymentMethod == 'on-site' ? 'active' : ''}>

                                                                            <button onClick={() => this.setState({ paymentMethod: 'on-site' })} >{this.props.translate("Plaćanje pouzećem")}</button>
                                                                            <div className="payment-account">
                                                                                <h6>{this.props.translate('VAŽNA OBAVIJEST!')}</h6>
                                                                                <p>{this.props.translate('Plaćanje pouzećem (COD) moguće je samo za domaće kupce iz Hrvatske (HR). Ako ste odabrali plaćanje pouzećem a nalazite se u drugoj državi, nećemo moći ispuniti vašu narudžbu. Slobodno nam se obratite na info@rossen.com.hr i rado ćemo vam ponuditi druge mogućnosti plaćanja.')}</p>
                                                                                <button onClick={
                                                                                    () => {
                                                                                        this.setState({
                                                                                            step: 3
                                                                                        }, () => {
                                                                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                                                                            this.props.socketIOClient.emit('executeOrder', { paymentOnSite: true });
                                                                                        })

                                                                                    }
                                                                                }>{this.props.translate('NARUČI')}</button>

                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        null
                                                                    } */}
                                                                    {/* <div className={this.state.paymentMethod == 'payment-on-account' ? 'active' : ''}>
                                                                        <button onClick={() => this.setState({ paymentMethod: 'payment-on-account' })} >{this.props.translate("Uplata na racun")}</button>
                                                                        <div>
                                                                            <div className="payment-account">
 
                                                                                <p>{this.props.translate('Čim je iznos prebačen, pokrećemo isporuku.')}</p>
                                                                                <button onClick={
                                                                                    () => {
                                                                                        this.setState({
                                                                                            step: 3
                                                                                        }, () => {
                                                                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                                                                            this.props.socketIOClient.emit('executeOrder', { paymentOnAccount: true });
                                                                                        })

                                                                                    }
                                                                                }>{this.props.translate('NARUČI')}</button>
                                                                            </div>

                                                                        </div>

                                                                    </div> */}

                                                                </div>




                                                            </Col>



                                                            :
                                                            null
                                                    }

                                                </Row>
                                            </Container>



                                            : null}



                                        {this.state.step == 3 ?
                                            <Container className="box-container order-loading">
                                                <Row>
                                                    <Col lg="12">
                                                        <div className="order-loader">
                                                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                        <h3 className="order-done-title">{this.props.translate('Obrada narudžbe je u toku.')}</h3>
                                                        <h6 className="order-done-subtitle">{this.props.translate('Molimo pričekajte.')}</h6>
                                                    </Col>

                                                </Row>
                                            </Container>

                                            :
                                            null

                                        }



                                        {this.state.step === 4 ?
                                            <Container className="box-container order-done">
                                                <Row>
                                                    <Col lg="12">
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <Player
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets4.lottiefiles.com/packages/lf20_XUFuoA.json"
                                                                style={{ height: '200px', width: '200px' }}
                                                            ></Player>
                                                        </div>
                                                        <h3 className="order-done-title">{this.props.translate('Vaša narudžba je uspješno izvršena.')}</h3>
                                                        {this.state.ShippingCountry == 'Hrvaška' || this.state.ShippingCountry == 'Nemčija' || this.state.ShippingCountry == 'Avstrija' || this.state.ShippingCountry == 'Sverige' ?
                                                            <h6 className="order-done-subtitle">{this.props.translate('Dostava je u roku od dva dana.')}</h6> :
                                                            <h6 className="order-done-subtitle">{this.props.translate('Package is being sent via Post. Delivery takes 7-10 days. If you would like to change delivery to express, contact us in chat.')}</h6>
                                                        }

                                                        {/* {
                                                            this.state.paymentMethod == 'payment-on-account' ?
                                                                <div>
                                                                    <div className="payment-account">
                                                                        <h6>SI56 0284 3026 4381 828<br />
                                                                            NLB d.d. Ljubljana<br />
                                                                            SWIFT: LJBASI2X</h6>
                                                                        <p>Biogardenia d.o.o.<br />
                                                                            Taborska cesta 38d<br />
                                                                            1290 Grosuplje<br />
                                                                            {this.props.translate('Sverige')}<br />
                                                                        </p>

                                                                    </div>

                                                                </div>
                                                                :
                                                                null
                                                        } */}
                                                    </Col>

                                                </Row>
                                            </Container>

                                            : null
                                        }


                                    </Col>

                                </Row>
                            </Container>

                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(CartPage));
