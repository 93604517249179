import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import HomeHeader from './header/homeHeader';

import Snowfall from 'react-snowfall'
import Article from '../components/article'

const month = new Date().getMonth();
const day = new Date().getDate();

export const DefaultLayout = (Wrapped) => (props) => {
    let numOfProducts = props && props.gratisProductMessagePopUp && props.gratisProductMessagePopUp.numOfProducts ? Number(props.gratisProductMessagePopUp.numOfProducts) : null;
    let numOfProductsString = null;
    if (numOfProducts > 1) {
        numOfProductsString = numOfProducts;
        // if (numOfProducts === 2) numOfProductsString = 'DVA';
        // else if (numOfProducts === 3) numOfProductsString = 'TRI';
        // else if (numOfProducts === 4) numOfProductsString = 'ČETIRI';
        // else if (numOfProducts === 5) numOfProductsString = 'PET';
        // else if (numOfProducts === 6) numOfProductsString = 'ŠEST';
        // else if (numOfProducts === 7) numOfProductsString = 'SEDAM';
        // else if (numOfProducts === 8) numOfProductsString = 'OSAM';
        // else numOfProductsString = String(numOfProductsString);
    }
    return (

        <div onTouchEnd={props.touchEnd} onTouchMove={props.touchMove} onClick={() => {
            if (props.addToCartPopUp)
                props.showAddToCartPopUp(null)
        }} >

            <HomeHeader {...props} />
            <Wrapped {...props} />

            {
                props.addToCartPopUp ?
                    <div className='add-to-cart-popup-wrap' onClick={() => props.showAddToCartPopUp(null)}>
                        <div className='add-to-cart-popup'>
                            {
                                props.gratisProductMessagePopUp && props.gratisProductMessagePopUp.remaining ?
                                    <>
                                        <h3>{props.translate('Nedostaje vam')} {props.gratisProductMessagePopUp.remaining.formatPrice(props.currency)} {props.translate('do')} <span style={{ color: '#F7617E' }}>{numOfProductsString ? `${numOfProductsString} ` : ''}{props.translate('POKLON PROIZVODA!')}</span></h3>
                                        {
                                            props.gratisProductMessagePopUp.percent && props.gratisProductMessagePopUp.sum && props.gratisProductMessagePopUp.limit ?
                                                <div className='gratis-product-message-percent-wrap'>
                                                    <div className='percent-line'><div className='percent-line-pink' style={{ width: `${props.gratisProductMessagePopUp.percent}%` }}></div></div>
                                                    <div className='gratis-product-message-percent-prices'>
                                                        <h6>{props.gratisProductMessagePopUp.sum.formatPrice(props.currency)}</h6>
                                                        <h6>{props.gratisProductMessagePopUp.limit.formatPrice(props.currency)}</h6>

                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <h3>{props.translate('Artikal je uspješno dodat u korpu.')}</h3>

                            }
                            <div className='buttons'>

                                <button onClick={() => props.showAddToCartPopUp(null)}>{props.translate('Nastavi kupovati')}</button>
                                <Link to='/cart'><button onClick={() => props.showAddToCartPopUp(null)}>{props.translate('Idi u korpu')}</button></Link>
                            </div>
                            <p>{props.translate('Kupci su takodje kupovali')}</p>
                            <div className='similar-articles'>
                                {props.addToCartPopUp.products.map((item) => {
                                    return <Article addToCart={() => props.addToCart(item)} lang={props.lang}
                                        _id={item._id}
                                        slug={item.slug}
                                        alias={item.Alias}
                                        image={Object.translate(item, 'Images', props.lang) ? Object.translate(item, 'Images', props.lang)[0] : null}
                                        title={item.Name}
                                        package={item.package}
                                        currency={props.currency}
                                        discount={item.discount}
                                        price={item.price ? item.price : 0}
                                        inStock={item.StockLevel > 0 ? true : false}
                                        translate={props.translate}>
                                    </Article>

                                })}
                            </div>
                        </div>
                    </div>

                    :
                    null
            }


           {(day > 5 && month == 11) || (day < 15 && month == 0) ?

                <div className="snowfall" style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, pointerEvents: 'none', zIndex: 9999 }}>
                    <Snowfall snowflakeCount={window.innerWidth < 768 ? 100 : 200}></Snowfall>
                </div>
                :
                null
            }



        </div>
    );
};

export default DefaultLayout;