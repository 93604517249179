import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Text from './fields/text';
import Textarea from './fields/textarea';

import Select from './fields/select';
import Check from './fields/check';

import {
    Col, Row, Container
} from 'reactstrap';


const required = value => value ? undefined : "Required"

const renderSelectField = ({
    input,
    placeholder,
    label,
    id,
    translate,
    meta: { touched, error },
    children,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        id={id}
        translate={translate}
        {...input}
        children={children}
    />
)



const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    id,
    translate,
    meta: { touched, error },
}) => (

    <Text
        placeholder={placeholder}
        label={label}
        type={type}
        id={id}
        translate={translate}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)

const renderCheckField = ({
    input,
    placeholder,
    label,
    type,
    id,
    translate,
    meta: { touched, error },
}) => (

    <Check
        placeholder={placeholder}
        label={label}
        type={type}
        id={id}
        translate={translate}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        type={type}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)

const countriesMapEn = ['Sweden', 'Denmark', 'Finland', 'Norway', 'Lithuania', 'Poland', 'Estonia']
const countriesMapHr = ['Švedska', 'Danska', 'Finska', 'Norveška', 'Litva', 'Poljska', 'Estonija']

function submitFailure(fieldList) {
    if (fieldList && Object.keys(fieldList).length) {
        for (var key in fieldList) {
            let field = key && key.toLowerCase()
            let id = `${field}-field`;
            if (field === 'address') {
                let field1 = document.getElementById(`${id}-1`);
                // let field2 = document.getElementById(`${id}-2`);
                if (field1 && !field1.value) {
                    id = `${field}-field-1`;
                } else {
                    id = `${field}-field-2`;
                }
            }
            if (field) {
                const elem = document.getElementById(id)
                if (elem) {
                    elem.focus()
                    return;
                }
            }

        }
    }

}

var orderForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(props);

    return (
        <form onSubmit={handleSubmit}>

            <Row>
                <Col lg="12">
                    <Field
                        name="Name"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Ime i prezime *')}
                        placeholder=""
                        validate={required}
                        id={'name-field'}
                        translate={props.translate}
                    ></Field>

                    <Field
                        name="Street"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Ulica *')}
                        placeholder=""
                        validate={required}
                        id={'street-field'}
                        translate={props.translate}

                    ></Field>

                    <Row>
                        <Col lg="6">
                            <Field
                                name="City"
                                type="text"
                                component={renderTextField}
                                label={props.translate('Grad *')}
                                placeholder=""
                                validate={required}
                                id={'city-field'}
                                translate={props.translate}
                            ></Field>
                        </Col>
                        <Col lg="6">
                            <Field
                                name="Zipcode"
                                type="text"
                                component={renderTextField}
                                label={props.translate('Poštanski broj *')}
                                placeholder=""
                                validate={required}
                                id={'zipcode-field'}
                                translate={props.translate}
                            ></Field>
                        </Col>

                    </Row>
                            {
                                props.lang == 'en' ?

                                    <Field
                                        name="Country"
                                        component={renderSelectField}
                                        label={props.translate('Država *')}
                                        placeholder=""
                                        validate={required}
                                        id={'country-field'}
                                        translate={props.translate}
                                    >
                                        {
                                            ['Sverige', 'Danmark', 'Finland', 'Norge', 'Litauen', 'Poland', 'Estland'].map((item, idx) => {
                                                return <option value={item}>{countriesMapEn[idx]}</option>
                                            })
                                        }

                                    </Field>

                                    :
                                    props.lang == 'hr' ?

                                        <Field
                                            name="Country"
                                            component={renderSelectField}
                                            label={props.translate('Država *')}
                                            placeholder=""
                                            validate={required}
                                            id={'country-field'}
                                            translate={props.translate}
                                        >
                                            {
                                                ['Sverige', 'Danmark', 'Finland', 'Norge', 'Litauen', 'Poland', 'Estland'].map((item, idx) => {
                                                    return <option value={item}>{countriesMapHr[idx]}</option>
                                                })
                                            }

                                        </Field>

                                        :

                                        <Field
                                            name="Country"
                                            component={renderSelectField}
                                            label={props.translate('Država *')}
                                            placeholder=""
                                            validate={required}
                                            id={'country-field'}
                                            translate={props.translate}
                                        >
                                            <option>Sverige</option>
                                            <option>Danmark</option>
                                            <option>Finland</option>
                                            <option>Norge</option>
                                            <option>Litauen</option>
                                            <option>Poland</option>
                                            <option>Estland</option>

                                        </Field>
                            }
                            {
                                props.Country === 'Norge' ? <p>
                                    * Från och med 1 januari 2024 ska du betala moms för alla varor som köps från utlandet, även de under 350 NOK. Detta gäller även om du har köpt en vara 2023, men får den levererad 2024. Handlar du från utländska nätbutiker som inte tar ut mervärdesskatt när du köper varan (VOEC) ska du betala mervärdesskatt och ev. tullavgift när varan anländer till Norge . Dessutom kan transportören (till exempel Posten) som transporterar varan ta ut en avgift för tullhantering.
                                </p>
                                :
                                null
                            }
                        {/* {props.Country == 'Schweiz' ?
                            <Col lg="6">
                                <Field
                                    name="Region"
                                    component={renderSelectField}
                                    label={props.translate('Regija *')}
                                    placeholder=""
                                    validate={required}

                                >
                                    <option>Zürich</option>
                                    <option>Bern / Berne</option>
                                    <option>Luzern</option>
                                    <option>Uri</option>
                                    <option>Schwyz</option>
                                    <option>Unterwalden</option>
                                    <option>Glarus</option>
                                    <option>Zug</option>
                                    <option>Freiburg / Fribourg</option>
                                    <option>Solothurn</option>
                                    <option>Basel</option>
                                    <option>Schaffhausen</option>
                                    <option>Appenzell</option>
                                    <option>Sankt Gallen</option>
                                    <option>Graubünden</option>
                                    <option>Aargau</option>
                                    <option>Thurgau</option>
                                    <option>Ticino</option>
                                    <option>Vaud</option>
                                    <option>Valais / Wallis</option>
                                    <option>Neuchâtel</option>
                                    <option>Genève</option>
                                    <option>Jura</option>
                                </Field>
                            </Col>
                            :
                            null
                        } */}
                    <Field
                        name="Phone"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Telefon *')}
                        placeholder=""
                        validate={required}
                        id={'phone-field'}
                        translate={props.translate}
                    ></Field>
                    <Field
                        name="EMail"
                        type="email"
                        component={renderTextField}
                        label={props.translate('Email *')}
                        placeholder=""
                        validate={required}
                        id={'email-field'}
                        translate={props.translate}
                    ></Field>

                    <Field
                        name="acceptTerms"
                        type="checkbox"
                        component={renderCheckField}
                        label={<>{props.translate('Pročitao sam i prihvatam ')} <Link to='/page/GTC' className="text-primary text-bold">{props.translate('Uslove i odredbe')}</Link></>}
                        placeholder=""
                        validate={required}

                    ></Field>


                </Col>
                {/*<Col lg="6">
                    <Field
                        name="Node"
                        type="text"
                        component={renderTextareaField}
                        label={props.translate('Napomena')}
                        placeholder=""
                    ></Field>

    </Col>*/}


            </Row>

            <p>{props.translate('* Obavezna polja')}</p>

            <button type="submit" className="button">{props.translate('NARUČI')}</button>


        </form>

    )
}

orderForm = reduxForm({
    form: 'orderForm',  // a unique identifier for this form
    onSubmitFail: (fieldList) => submitFailure(fieldList)
})(orderForm)

const selector = formValueSelector('orderForm');

orderForm = connect(state => {
    return {
        Country: selector(state, 'Country'),
    }


}, {
    ChangeRegion: value => change("orderForm", "Region", value),

})(orderForm)

export default orderForm;