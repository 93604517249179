import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';

import star from '../assets/svg/star.svg';
import fullStar from '../assets/svg/full-star.svg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import LoginForm from '../components/forms/loginForm';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            rating: {
                '1': 0,
                '2': 0
            }
        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }

    emitRating = () => {
        console.log('emitRating', this.props[0].match.params.question, this.props[0].match.params.rating)

        this.props.socketIOClient.emit('rate', {
            email: this.props[0].match.params.email,
            question: this.props[0].match.params.question,
            rating: parseInt(this.props[0].match.params.rating)
        })
    }
    emitCustomRating = (question, rating) => {
        console.log('emitCustomRating', question, rating)
        this.props.socketIOClient.emit('rate', {
            email: this.props[0].match.params.email,
            question: question,
            rating: parseInt(rating)
        })
    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('rate', (data) => {
            this.setState({rating: data});
        });


        this.emitRating();
    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    //


    render() {

        console.log(this.state.rating);

        return (
            <div className="account-wrap">

                <Container>
                    <Row>
                        <Col lg="12" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="12" className={'ratings-box'}>
                                                    <h6>{this.props.translate('Ste pri nas našli vse izdelke, ki ste jih iskali?')}</h6>
                                                    <div className='stars'>
                                                        <button onClick={() => this.emitCustomRating('1', '1')}> {this.state.rating && this.state.rating['1'] >= 1 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('1', '2')}> {this.state.rating && this.state.rating['1'] >= 2 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('1', '3')}> {this.state.rating && this.state.rating['1'] >= 3 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('1', '4')}> {this.state.rating && this.state.rating['1'] >= 4 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('1', '5')}> {this.state.rating && this.state.rating['1'] >= 5 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                    </div>
                                                    <h6>{this.props.translate('Kako bi ocenili svojo nakupovalno izkušnjo?')}</h6>
                                                    <div className='stars'>
                                                        <button onClick={() => this.emitCustomRating('2', '1')}> {this.state.rating && this.state.rating['2'] >= 1 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('2', '2')}> {this.state.rating && this.state.rating['2'] >= 2 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('2', '3')}> {this.state.rating && this.state.rating['2'] >= 3 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('2', '4')}> {this.state.rating && this.state.rating['2'] >= 4 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                        <button onClick={() => this.emitCustomRating('2', '5')}> {this.state.rating && this.state.rating['2'] >= 5 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                    </div>

                                                </Col>

                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(LoginPage));
