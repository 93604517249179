import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';

import star from '../assets/svg/star.svg';
import fullStar from '../assets/svg/full-star.svg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import LoginForm from '../components/forms/loginForm';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            rating: {

            }
        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }

    emitRating = () => {
        this.props.socketIOClient.emit('rateProduct', {
            orderId: this.props[0].match.params.orderId,
            productId: this.props[0].match.params.productId,
            rating: parseInt(this.props[0].match.params.rating)
        })
    }
    emitCustomRating = (productId, rating) => {
        this.props.socketIOClient.emit('rateProduct', {
            orderId: this.props[0].match.params.orderId,
            productId: productId,
            rating: parseInt(rating)
        })
    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('rateProduct', (data) => {
            this.setState({ rating: data });
        });


        this.emitRating();
    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    //


    render() {

        console.log(this.state.rating);

        return (
            <div className="account-wrap">

                <Container>
                    <Row>
                        <Col lg="12" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="12" className={' ratings-product'}>
                                                    <h1>{this.props.translate('Ste zadovoljni s kupljenimi artikli?')}</h1>
                                                    <h3>{this.props.translate('Delite svoje mnenje o artiklih. Tako pomagate drugim izbrati pravi artikel.')}</h3>

                                                    {
                                                        this.state.rating ?
                                                            Object.values(this.state.rating).map((item, idx) => {
                                                                return (
                                                                    <div className='rating-product'>
                                                                        <img src={item.Images && item.Images.si && item.Images.si[0]} />
                                                                        <div>
                                                                            <p>{this.props.translate('Kakšna je vaša ocena artikla?')}</p>
                                                                            <h6>{item.package && item.package.name && item.package.name.si}</h6>
                                                                            <div className='stars'>
                                                                                <button onClick={() => this.emitCustomRating(item._id, '1')}> {item.rating >= 1 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                                                <button onClick={() => this.emitCustomRating(item._id, '2')}> {item.rating >= 2 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                                                <button onClick={() => this.emitCustomRating(item._id, '3')}> {item.rating >= 3 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                                                <button onClick={() => this.emitCustomRating(item._id, '4')}> {item.rating >= 4 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                                                <button onClick={() => this.emitCustomRating(item._id, '5')}> {item.rating >= 5 ? <Isvg src={fullStar} /> : <Isvg src={star} />}</button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }

                                                </Col>

                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(LoginPage));
