import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import image from '../assets/images/no-image.jpg';

export class Article extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <div className="article">
                <article >
                    {/* <Link to={`/product/${this.props.alias}/${this.props._id}`}> <img onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? this.props.image ? this.props.image : image : image} /></Link> */}
                    <Link to={this.props.slug ? `/products/${this.props.slug}` : `/product/${this.props.alias}/${this.props._id}`}> <img onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? this.props.image ? this.props.image : image : image} /></Link>

                    {this.props.showDiscount ?
                        <div className="top"> -{this.props.discount}%</div>
                        :
                        null
                    }
                    {this.props.newArticle ?
                        <div className="new">{this.props.translate('NOVO')}</div>
                        :
                        null
                    }
                    {/* <Link to={`/product/${this.props.alias}/${this.props._id}`} > <h6>{Object.translate(this.props, 'title', this.props.lang)}</h6></Link> */}
                    <Link to={this.props.slug ? `/products/${this.props.slug}` : `/product/${this.props.alias}/${this.props._id}`} > <h6>{Object.translate(this.props, 'title', this.props.lang)}</h6></Link>

                    {this.props.package && this.props.package[0] && this.props.package[0].price ? <p>
                        <span className="old-price">{this.props.package && this.props.package[0] && this.props.package[0].discount && this.props.package[0].price && ((this.props.package[0].price * 1) * (1 + (this.props.package[0].upDiscount || this.props.package[0].discount) / 100)).formatPrice(this.props.currency)}</span>

                        <span className="price">{this.props.package[0].price.formatPrice(this.props.currency)} </span>
                    </p>

                        : null}                    <button onClick={() => this.props.addToCart()}>{this.props.translate('DODAJ U KORPU')}</button>

                </article>
            </div>

        )
    }
}

export default Article;