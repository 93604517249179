import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';
import banner3 from '../assets/images/banner3.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import brand from '../assets/images/brand.jpg';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import BlogArticle from '../components/blogArticle';
import HomeSlider from '../components/homeSlider';
import ReviewsSlider from '../components/reviewsSlider';
import Slider from "react-slick";


import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            activeIndex: 0,
            brands: [],
            banners: [
            ],

            slides: [

            ],

        };

    }



    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchSlides', (data) => {
            console.log(data);
            this.setState({
                slides: data
            })
        });

        this.props.socketIOClient.on('fetchBanners', (data) => {
            console.log(data);
            this.setState({
                banners: data
            })
        });

        this.props.socketIOClient.on('fetchBrands', (data) => {
            console.log(data);
            this.setState({
                brands: data
            })
        });



        this.props.socketIOClient.emit("fetchSlides", {});
        this.props.socketIOClient.emit("fetchBanners", {});

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchSlides");
        this.props.socketIOClient.removeAllListeners("fetchBanners");

    }



    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth < 768 ? 2 : 4,
            slidesToScroll: 1,

        };

        console.log(this.props.categories);
        let isMobile = typeof window !== 'undefined' && window.innerWidth <= 768 ? true : false;
        return (
            <div className="home-wrap">


                <HomeSlider items={this.state.slides} lang={this.props.lang} translate={this.props.translate} />

                <section className="section section-categories">
                    <Container>
                        {/* <Row>
                            {
                                this.props.categories.map((item, idx) => {
                                    return (
                                        <Col lg={3} xs={12}>
                                            <Link to={`/category${item.Breadcrumb}`}>
                                                <div className="category-item">
                                                    <img src={window.innerWidth < 768 ? Object.translate(item, 'MobileImage', this.props.lang) : window.innerWidth >= 768 && window.innerWidth < 992 ? Object.translate(item, 'TabletImage', this.props.lang) : Object.translate(item, 'Image', this.props.lang)} />
                                                    <div className="name">
                                                        <h6 style={this.props.lang == 'de' ? { fontSize: 28 } : {}}>{Object.translate(item, 'Name', this.props.lang)}</h6>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    )
                                })
                            }
                        </Row> */}
                        {/* <Row>
                            <div className='home-page-categories-wrap'>

                                {
                                    this.props.categories.map((item, idx) => {
                                        if (window.innerWidth < 768 ? Object.translate(item, 'MobileImage', this.props.lang) : window.innerWidth >= 768 && window.innerWidth < 992 ? Object.translate(item, 'TabletImage', this.props.lang) : Object.translate(item, 'Image', this.props.lang))
                                            return (
                                                <div className='home-page-categories-item'>
                                                    <Link to={`/category${item.Breadcrumb}`}>
                                                        <div className="category-item">
                                                            <img src={window.innerWidth < 768 ? Object.translate(item, 'MobileImage', this.props.lang) : window.innerWidth >= 768 && window.innerWidth < 992 ? Object.translate(item, 'TabletImage', this.props.lang) : Object.translate(item, 'Image', this.props.lang)} />
                                                            <div className="name">
                                                                <h6 style={this.props.lang == 'de' ? { fontSize: 22 } : {}}>{Object.translate(item, 'Name', this.props.lang)}</h6>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                            )
                                    })
                                }
                            </div>
                        </Row> */}
                    </Container>
                </section>


                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    {this.props.translate('POPULARNI')}   <span>{this.props.translate('PROIZVODI')}</span>
                                </h2>
                            </Col>

                            {
                                this.props.popularProducts.filter((item, idx) => (isMobile && idx < 6) || !isMobile).map((item, idx) => {
                                    return (
                                        <Col lg="3" xs="6">
                                            <Article addToCart={() => this.props.addToCart(item)} lang={this.props.lang}
                                                _id={item._id}
                                                slug={item.slug}
                                                alias={item.Alias}
                                                image={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null}
                                                title={item.Name}
                                                discount={item.discount}
                                                showDiscount={item.ShowDiscountPrice}
                                                currency={this.props.currency}
                                                package={item.package}
                                                price={item.price ? item.price : 0}
                                                inStock={item.StockLevel > 0 ? true : false}
                                                translate={this.props.translate}
                                            >
                                            </Article>
                                        </Col>
                                    )
                                })
                            }
                            {/* <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.popularProducts.map((item, idx) => {
                                            return (
                                                <Article addToCart={() => this.props.addToCart(item)} lang={this.props.lang}
                                                    _id={item._id}
                                                    slug={item.slug}
                                                    alias={item.Alias}
                                                    image={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null}
                                                    title={item.Name}
                                                    discount={item.discount}
                                                    showDiscount={item.ShowDiscountPrice}
                                                    currency={this.props.currency}
                                                    package={item.package}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}
                                                >
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col> */}


                        </Row>
                    </Container>
                </section>

                { /*
                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    PROIZVODI  <span>NA AKCIJI</span>
                                </h2>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.offerProducts.map((item, idx) => {
                                            return (
                                                <Article addToCart={() => this.props.addToCart(item)} lang={this.props.lang}
                                                    _id={item._id}
                                                    slug={item.slug}
                                                    alias={item.Alias}
                                                    image={Object.translate(item, 'Images', this.props.lang ) ? Object.translate(item, 'Images', this.props.lang )[0]  : null}
                                                    title={item.Name}
                                                    discount={item.discount}
                                                    showDiscount={item.ShowDiscountPrice}
                                                    currency={this.props.currency}
                                                    package={item.package}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}
                                                    showDiscount
                                                >
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>


                        </Row>
                    </Container>
                </section>
                */
                }

                <section className="section section-banners">

                    <Container>
                        <Row>
                            {this.state.banners[0] ?
                                <Col lg="12">
                                    <Link to={this.state.banners[0].Link}>   <img src={Object.translate(this.state.banners[0], 'Image', this.props.lang)} /></Link>
                                </Col>
                                : null
                            }
                        </Row>
                    </Container>
                </section>



                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    {this.props.translate('NOVO')} <span>{this.props.translate('U PONUDI')}</span>
                                </h2>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.newestProducts.map((item, idx) => {
                                            return (
                                                <Article addToCart={() => this.props.addToCart(item)} lang={this.props.lang}
                                                    _id={item._id}
                                                    slug={item.slug}
                                                    alias={item.Alias}
                                                    image={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null}
                                                    title={item.Name}
                                                    package={item.package}
                                                    currency={this.props.currency}
                                                    discount={item.discount}
                                                    showDiscount={item.ShowDiscountPrice}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}
                                                    newArticle
                                                >
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>


                        </Row>
                    </Container>
                </section>



                {/* <ReviewsSlider items={this.state.reviews} />*/}

                {/*

                <section className="section section-news">
                    <Container>
                        <Row>
                            <Col lg="12" className="news-title-container">
                                <h2>
                                    {this.props.translate('NOVOSTI')} <span>{this.props.translate('I OBAVEŠTENJA')}</span>
                                </h2>
                            </Col>
                            {
                                this.props.news.map((item, idx) => {
                                    if (idx < 3)
                                        return (
                                            <Col lg="4">
                                                <BlogArticle {...item} translate={this.props.translate}></BlogArticle>
                                            </Col>
                                        )
                                })
                            }

                        </Row>
                    </Container>
                </section>

                        */}

                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(HomePage));
